(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/views/index.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.section_odds_live=_svs.banner_layouts.section_odds_live||{};
_svs.banner_layouts.section_odds_live.templates=_svs.banner_layouts.section_odds_live.templates||{};
svs.banner_layouts.section_odds_live.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":30,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(26, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":2},"end":{"line":58,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"body") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":2},"end":{"line":63,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"hero-actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"callToActions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":4},"end":{"line":69,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventCta") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-eyebrow js-hero-eyebrow\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useH2") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2 class=\"hero-eyebrow-text js-hero-eyebrow-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eyebrowManualAdmin") || (depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eyebrowManualAdmin","hash":{},"data":data,"loc":{"start":{"line":20,"column":59},"end":{"line":20,"column":81}}}) : helper)))
    + "</h2>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <h1 class=\"hero-eyebrow-text js-hero-eyebrow-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eyebrowManualAdmin") || (depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eyebrowManualAdmin","hash":{},"data":data,"loc":{"start":{"line":22,"column":61},"end":{"line":22,"column":83}}}) : helper)))
    + "</h1>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-eyebrow js-hero-eyebrow\">\n      <span class=\"hero-eyebrow-group js-hero-eyebrow-group\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"group") : stack1), depth0))
    + "</span>\n      <span class=\"hero-eyebrow-time js-hero-eyebrow-time\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"hero-title hero-event-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":45},"end":{"line":32,"column":86}}})) != null ? stack1 : "")
    + "\">\n        <span class=\"hero-event-title-ellipsis hero-event-title-home js-hero-event-title-home\">\n          <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + "</span>\n        </span>\n        <span class=\"hero-delimiter js-hero-delimiter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":54},"end":{"line":36,"column":94}}})) != null ? stack1 : "")
    + "\">\n          <span class=\"hero-score js-hero-score-home\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":37,"column":66},"end":{"line":37,"column":119}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":38,"column":71}}})) != null ? stack1 : "")
    + "</span>\n          <span class=\"delimiter\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"nameDelimiter") : stack1), depth0))
    + "</span>\n          <span class=\"hero-score js-hero-score-away\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":41,"column":66},"end":{"line":41,"column":119}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":71}}})) != null ? stack1 : "")
    + "</span>\n        </span>\n        <span class=\"hero-event-title-ellipsis hero-event-title-away js-hero-event-title-away\">\n          <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "</span>\n        </span>\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return " hero-event-title-live";
},"12":function(container,depth0,helpers,partials,data) {
    return " hero-delimiter-score";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    return "0";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</span>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0));
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0))
    + "</span>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"hero-time\">\n        <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span>\n      </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noEvent") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":2},"end":{"line":58,"column":2}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-title hero-event-title\">\n      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"noEvent") || (depth0 != null ? lookupProperty(depth0,"noEvent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noEvent","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":23}}}) : helper)))
    + "</span>\n    </div>\n  ";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-body\">\n      <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"body","hash":{},"data":data,"loc":{"start":{"line":61,"column":9},"end":{"line":61,"column":17}}}) : helper)))
    + "</p>\n    </div>\n";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":6},"end":{"line":68,"column":15}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":67,"column":75}}}))
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1),"target":"internal","href":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkUrl") : stack1),"data-click-tracking":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":6},"end":{"line":78,"column":13}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-hero-hero"),depth0,{"name":"components-lb_ui-hero-hero","hash":{"width":(depth0 != null ? lookupProperty(depth0,"width") : depth0),"trackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"tab":(depth0 != null ? lookupProperty(depth0,"tab") : depth0),"slim":(depth0 != null ? lookupProperty(depth0,"slim") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0),"loading":(depth0 != null ? lookupProperty(depth0,"loading") : depth0),"invertArrow":(depth0 != null ? lookupProperty(depth0,"invertArrow") : depth0),"image":(depth0 != null ? lookupProperty(depth0,"image") : depth0),"height":(depth0 != null ? lookupProperty(depth0,"height") : depth0),"dataset":(depth0 != null ? lookupProperty(depth0,"dataset") : depth0),"cube":(depth0 != null ? lookupProperty(depth0,"cube") : depth0),"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"class":(depth0 != null ? lookupProperty(depth0,"classes") : depth0),"branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-section_odds_live-index'] = svs.banner_layouts.section_odds_live.templates.index;
})(svs, Handlebars);


 })(window);